<template>
    <div class="container flex flex-col">
        <div class="bg-white-pure list-wrapper">
            <div class="mb-8">
                <BackLink :backRoute="reportsListRoute" :linkText="goBackText" />
            </div>

            <h4 class="font-display font-semibold text-xl mb-4 uppercase">Workshop Attendance Summary</h4>
            <v-data-table
                :headers="headers"
                :items="workshopData"
            ></v-data-table>
        </div>
    </div>
</template>

<script>
import BackLink from '@/components/shared/BackLink'

export default {
    name: 'Workshop',
    components: { BackLink },
    data() {
        return {
            goBackText: 'Back to Reports',
            reportsListRoute: {
                name: 'reports'
            },
            headers: [
                {
                    text: 'Workshop',
                    value: 'workshop',
                },
                {
                    text: 'Workshop Type',
                    value: 'workshopType',
                },
                {
                    text: 'TSU Location',
                    value: 'TSULocation',
                },
                {
                    text: 'Date',
                    value: 'date',
                },
                {
                    text: 'Time',
                    value: 'time',
                },
                {
                    text: 'No. of Attendees',
                    value: 'NoOfAttendees',
                },
                {
                    text: 'Attendees',
                    value: 'attendees',
                },
                {
                    text: 'Gender',
                    value: 'gender',
                },
                {
                    text: 'Age',
                    value: 'age',
                },
                {
                    text: 'Postcode',
                    value: 'postcode',
                },
            ],
            workshopData: [
                {
                    name: 'Frozen Yogurt'
                },
            ]
        }
    }
}
</script>